'use strict';
var cur_call = null;
var confMan = null;
var vertoPhone = "";
var verto;
var ringing = false;
var chatting_with = false;
var clearAutoHangup = 0;
var clearAutoReply = 0;
var phoneState;
var telConnect;
var telRinging;
var telActive;
var telBePopup;
/*$( ".selector" ).pagecontainer({ "theme": "a" });*/

export function clearConfMan() {
	if (confMan) {
		confMan.destroy();
		confMan = null;
	}
	chatting_with = null;
}

var callbacks = {
	onMessage: function (verto, dialog, msg, data) {
		console.log("msg=", msg);
	},
	onDialogState: function (d) {
		console.log("d=",d);
		cur_call = d;
		if (d.state == $.verto.enum.state.ringing) {
			ringing = true;
		} else {
			ringing = false;
		}
		console.log("vertoStatus=",d.state.name);
		phoneState = d.state;
		switch (d.state) {
			case $.verto.enum.state.ringing:
				// 呼入电话
				// $("#incomingCall").show();
				// $("#ansbtn").click(function () {
				// 	cur_call.answer({
				// 		useStereo: $("#use_stereo").is(':checked'),
				// 		callee_id_name: $("#name").val(),
				// 		callee_id_number: $("#cid").val(),
				// 	});
				// 	$("#incomingCall").hide();
				// });
				// $("#declinebtn").click(function () {
				// 	if (cur_call != null) {
				// 		cur_call.hangup({ "cause": "CALL_REJECTED" });
				// 	}
				// 	$("#incomingCall").hide();
				// });
				// vertoPhone = (d.cidString()).substring(0, 12);
				// if (vertoPhone.substring(0, 2) == "01") {
				// 	vertoPhone = (d.cidString()).substring(1, 12)
				// }
				// $("#caller_id").text($("#ola_extn").val());
				// $("#dest_number").text(vertoPhone);
				// var autoReply = $("#autoReply").val()
				// var autoHangup = $("#autoHangup").val()
				// if (parseInt(autoReply) >= 0) {
				// 	clearAutoReply = setTimeout(function () {
				// 		cur_call.answer({
				// 			useStereo: $("#use_stereo").is(':checked'),
				// 			callee_id_name: $("#name").val(),
				// 			callee_id_number: $("#cid").val(),
				// 		});
				// 		$("#incomingCall").hide();
				// 	}, parseInt(autoReply) * 1000)
				// }
				// if (parseInt(autoHangup) >= 0) {
				// 	clearAutoHangup = setTimeout(function () {
				// 		cur_call.hangup({ "cause": "CALL_REJECTED" });
				// 		$("#incomingCall").hide();
				// 	}, parseInt(autoHangup) * 1000);
				// }

				// if (d.params.wantVideo) {
				// 	$("#vansbtn").click(function () {
				// 		$("#use_vid").prop("checked", true);
				// 		cur_call.answer({
				// 			useVideo: true,
				// 			useStereo: $("#use_stereo").is(':checked')
				// 		});
				// 	});
				// } else {
				// }
				break;
			case $.verto.enum.state.trying:
				// 呼叫
				// $("#declinebtn").click(function () {
				// 	cur_call.hangup({ "cause": "CALL_REJECTED" });
				// 	$("#incomingCall").hide();
				// });
				break;
			case $.verto.enum.state.early:
				console.log("对方响铃")
				telRinging();
				// 对方响铃
				// $("#declinebtn").click(function () {
				// 	cur_call.hangup({ "cause": "CALL_REJECTED" });
				// 	$("#incomingCall").hide();
				// });
				break;
			case $.verto.enum.state.active:
				// 通话中
				console.log("通话中")
				telActive();
				clearTimeout(clearAutoHangup);
				clearTimeout(clearAutoReply);
				// $("#declinebtn").click(function () {
				// 	verto.hangup();
				// 	cur_call = null;
				// 	$("#incomingCall").hide();
				// });
				break;
			case $.verto.enum.state.hangup:
			case $.verto.enum.state.destroy:
				telBePopup();
				// 通话结束
				clearConfMan();
				cur_call = null;
				// $("#incomingCall").hide();
				break;
			default:
				// $("#incomingCall").hide();
				break;
		}
	},
	onWSLogin: function (v, success) {
		// $("#incomingCall").hide();
		if (success) {
			// websocket链接成功
			console.log("websocket链接成功")
			telConnect();
			// $("#status").text("Connected").css("color", "#098029");
			// $("#connect").hide();
			// $("#DisConnect").show();
			// $("#ola_extn").attr("disabled", true);
			// $("#agentPwd").attr("disabled", true);
			// $("#serIp").attr("disabled", true);
			// $("#serPort").attr("disabled", true);
		} else {
			// websocket链接失败
			console.log("websocket链接失败")
			telBePopup()
			// $("#status").text("Disconnected").css("color", "#383F42");
		}
	},
	onWSClose: function (v, success) {
		// websocket关闭
		console.log("websocket关闭")
		// telBePopup
		// $("#incomingCall").hide();
		// $("#status").text("Disconnected").css("color", "#383F42");
		// $("#ola_extn").attr("disabled", false);
		// $("#agentPwd").attr("disabled", false);
		// $("#serIp").attr("disabled", false);
		// $("#serPort").attr("disabled", false);
	},
	onEvent: function (v, e) {
		// $("#incomingCall").hide();
		console.debug("GOT EVENT", e);
	},
};

export function loginVerto(vertoExto, vertoAgentPwd, vertoSerIp, vertoSerPort, telConnectFnc, telBePopupFnc) {
	telConnect = telConnectFnc;
	telBePopup = telBePopupFnc;
	verto = new $.verto({
		login: vertoExto + "@" + vertoSerIp,
		passwd: vertoAgentPwd,
		socketUrl: "wss://" + vertoSerIp + ":" + vertoSerPort + "/webrtc",
		tag: "webcam",
		ringFile: "sounds/bell_ring2.wav",
		videoParams: {
			"minWidth": "1280",
			"minHeight": "720",
			"minFrameRate": 30
		},
		audioParams: {
			googAutoGainControl: false,
			googNoiseSuppression: false,
			googHighpassFilter: false
		}
	}, callbacks);
}


export function doCall(vertoExto, phoneNumber, telRingingFnc, telActiveFnc) {
	telRinging = telRingingFnc;
	telActive = telActiveFnc;
	cur_call = verto.newCall({
		destination_number: phoneNumber,
		caller_id_name: vertoExto,
		caller_id_number: vertoExto
	});
}
export function logout() {
	// verto.logout();
}

export function decline() {
	if(phoneState == $.verto.enum.state.ringing){
		// 呼入
	}else if(phoneState == $.verto.enum.state.trying){
		// 呼叫
		cur_call.hangup({ "cause": "CALL_REJECTED" });
	}else if(phoneState == $.verto.enum.state.early){
		// 对方响铃
		cur_call.hangup({ "cause": "CALL_REJECTED" });
	}else if(phoneState == $.verto.enum.state.active){
		// 通话中
		verto.hangup();
		cur_call = null;
	}else if(phoneState == $.verto.enum.state.hangup){
		// 通话中
	}else if(phoneState == $.verto.enum.state.destroy){
		// 通话结束
	}
}

export function keyboardClick(textContent) {
	if(cur_call != null && cur_call.state.name == "active"){
		console.log("textContent=",textContent)
		cur_call.dtmf(textContent);
	}
}
